import { useFeature } from 'flagged';

// needs to be snake_case
const featureFlags = [
	'installation',
	'areas_in_blueprint',
	'location_map',
	'upsell_blueprint',
	'comments',
	'alert_evidence',
	'measurement_events',
	'measurement_alerts',
	'experimental_heatmap',
	'heatmap_trial',
	'reports',
	'embedded_floor_plan',
	'tenant_communication'
] as const;
export type FeatureFlag = typeof featureFlags[number];
export type FeatureFlags = Record<FeatureFlag, boolean>;

export function useFeatureFlag(flag: FeatureFlag) {
	return useFeature(flag);
}

export function buildFeatureFlags(): FeatureFlags {
	const ret = Object.fromEntries(
		featureFlags.map(f => [f, process.env[`REACT_APP_FEATURE_${f.toUpperCase()}`] === 'true'])
	);
	if (process.env.REACT_APP_ECHO_FEATURES === 'true') {
		console.log('Features:', featureFlags);
	}
	return ret as FeatureFlags;
}
